<template>
    <div class="account-types mx-8 md:mx-16 flex flex-wrap justify-between text-dcblue">
        <div class="access-card">
            <h1 class="uppercase mb-8 font-semibold tracking-widest">Additional Content</h1>
            <div class="mb-6">
                <p class="font-bold text-xl text-left mb-4">Flag states</p>
                <div class="flex-wrap flex justify-between">
                    <div
                      v-for="flagState in getFlagStates"
                      v-bind:key="flagState.tid"
                      class="flex md:min-h-6 mb-2 w-240">
                        <img v-if="flagState.uri.length" class="self-center h-6 mr-2 flag-img" :src="flagState.uri" />
                        <p class="text-xl self-center text-left">{{flagState.desc}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div
          v-if="supportNode"
          class="access-card">
            <h1 class="uppercase mb-4 font-semibold tracking-widest">{{supportNode.data.title[0].value}}</h1>
            <img v-if="supportNode.data.field_card_icon.length" class="mx-auto mb-4" :src="supportNode.data.field_card_icon[0].url"/>

            <p class="font-bold  text-xl">{{supportNode.data.field_subheader[0].value}}</p>
            <p class="text-xl mb-4">senior regulatory expert consultancy</p>
            <p class="mb-4 pb-2 px-10">{{supportNode.data.field_description[0].value}}.</p>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'AdditionalMaterial',
    data: function () {
      return {
        supportNode: null
      }
    },
    computed: {
      ...mapGetters([
        'getFlagStates',
        'getOtherContents',
        'getStandards',
        'getUN'
      ]),
    },
    async created () {
      this.$store.dispatch('loadFlagStates');
      this.$store.dispatch('loadOtherContents');
      this.$store.dispatch('loadStandards');
      this.$store.dispatch('loadUN');

      this.supportNode = await this.axios.get('/node/30?_format=json');

    }
  }
</script>

<style scoped>
  .flag-img {
    width: 34px;
    height: 24px;
    object-fit: cover;
    border-radius: 5px;
  }

  .flag-states {
    height: 33rem;
  }

  .flag {
    max-width: 33%;
  }

  @media (max-width: 1024px) {
    .flag-states {
      height: 54rem;
    }

    .flag {
      max-width: 50%;
    }
  }

  @media (max-width: 768px) {
    .flag-states {
      height: auto;
    }

    .flag {
      max-width: 100%;
    }
  }
</style>
