<template lang="html">
    <div class="features page-content">
        <SearchSection></SearchSection>
        <ImageRightSection></ImageRightSection>
        <ImageLeftSection></ImageLeftSection>

    </div>
</template>

<script>
import SearchSection from "@/components/sections/SearchSection";
import ImageRightSection from "../components/sections/ImageRightSection";
import ImageLeftSection from "../components/sections/ImageLeftSection";


export default {
    components: {
        ImageLeftSection,
        ImageRightSection,
        SearchSection,
    }
}
</script>

<style lang="scss" scoped>
</style>
