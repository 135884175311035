<template lang="html">
    <div class="tutorials bg-dcgray pb-12 md:pb-32 page-content">
      <div class="sm:mx-20 lg:mx-36 xl:mx-48 mb-20">
        <p class="ml-4 text-xl">Watch our videos to learn how to use the marine regulations portal. Master the basics and also our advanced features.
        </p>
        <div class="container my-12 mx-auto px-4 md:px-12">
    <div class="flex flex-wrap -mx-1 lg:-mx-4">

        <!-- Column -->
        <div v-for="video in videos" :key="video.src" class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">

            <!-- Article -->
            <article class="overflow-hidden rounded-lg shadow-lg cursor-pointer"  @click="toggleModal(video)">

                <img alt="Placeholder" class="block h-auto w-full" :src="video.image">

                <header class="text-dcblue p-2 md:p-4">
                    <h3 class="text-lg font-semibold">
                        {{ video.title }}
                    </h3>
                    <p class="">{{ video.subtitle }}</p>
                </header>

            </article>
            <!-- END Article -->

        </div>
        <!-- END Column -->



    </div>
</div>
      </div>

        <!--Modal-->
        <div class="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center">
            <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                <div class="modal-container bg-white w-11/12 md:max-w-xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    <button class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"  @click="toggleModal">
                        <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                          <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                        <span class="text-sm">(Esc)</span>
                    </button>

                    <!-- Add margin if you want to see some of the overlay behind the modal-->
                    <div class="modal-content py-4 text-left px-6">
                        <!--Title-->
                        <div class="pb-3">
                            <h3 class="text-lg font-semibold">
                                {{ activeVideo.title }}
                            </h3>
                            <p class="">{{ activeVideo.subtitle }}</p>
                        </div>

                        <!--Body-->
                        <VideoSection v-if="showModal" id="landingVideo" :src="activeVideo.src" />

                        <!--Footer-->
                        <div class="flex justify-end pt-2">
                          <button class="modal-close px-4 bg-dcblue p-3 text-white hover:bg-indigo-400" @click="toggleModal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import VideoSection from "../components/sections/VideoSection";
export default {
    name: 'Videos',
    components: {
        VideoSection
    },
    data: function () {
        return {
            showModal: false,
            videos: [
                {
                    src: "https://www.youtube.com/embed/NbFOmJlraFk",
                    title: "Video Tutorial Episode 1",
                    subtitle: "New to the portal or trying us for free? Learn the basics by watching this tutorial.",
                    image: require("../assets/tutorialScreenshot.png")
                },
                {
                    src: "https://www.youtube.com/embed/7UBhPu-Sn1U",
                    title: "Video Tutorial Episode 2",
                    subtitle: "See how simple it is to keep updated with changes to regulations by watching this video.",
                    image: require("../assets/uniqueFeature.png")
                },
                {
                    src: "https://www.youtube.com/embed/gpzepnMY89E",
                    title: "Video Tutorial Episode 3",
                    subtitle: "Learn more about how the portal can save your time and how you can share documents",
                    image: require("../assets/episode3.png")
                }
            ],
            activeVideo: {}
        }
    },
    created() {
        document.onkeydown = (evt) => {
          evt = evt || window.event
          var isEscape = false
          if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc")
          } else {
            isEscape = (evt.keyCode === 27)
          }
          if (isEscape && document.body.classList.contains('modal-active')) {
            this.toggleModal()
          }
        };
    },
    methods: {
        toggleModal(video = {}) {
            this.showModal = !this.showModal;
            this.activeVideo = video
          const body = document.querySelector('body')
          const modal = document.querySelector('.modal')
          modal.classList.toggle('opacity-0')
          modal.classList.toggle('pointer-events-none')
          body.classList.toggle('modal-active');
        }
    }

}
</script>

<style lang="scss">
    .tutorials.page-content {
        padding-top: 30px;
    }
    .modal {
      transition: opacity 0.25s ease;
    }
    body.modal-active {
      overflow-x: hidden;
      overflow-y: visible !important;
    }
</style>
