<template>
  <div class="account-types mx-16 flex flex-wrap justify-between text-dcblue">
    <div
      v-if="accountTypes"
      class="access-card">
      <h1 class="uppercase mb-4 font-semibold tracking-widest">{{accountTypes.corporateAccount.name[0].value}}</h1>
      <img class="mx-auto mb-4" :src="accountTypes.corporateAccount.field_account_icon[0].url" />
      <p class="text-xl mb-4 md:px-24 pb-2 md:h-32">{{accountTypes.corporateAccount.field_desc[0].value}}</p>

      <div
        class="bg-white mx-4 p-6 flex flex-col">
        <div class="border-dcborder border-solid border-b py-1">
          <img class="mx-auto" src="@/assets/checkgreen.svg"/>
        </div>
        <div
          v-for="feature in accountTypes.corporateAccount.termNames"
          v-bind:key="feature"
          class="border-dcborder border-solid border-b py-1 font-semibold">
          {{ feature }}
        </div>
      </div>
    </div>

    <div
      v-if="accountTypes"
      class="access-card">
      <h1 class="uppercase mb-4 font-semibold tracking-widest">{{accountTypes.singleUserAccount.name[0].value}}</h1>
      <img class="mx-auto mb-4" :src="accountTypes.singleUserAccount.field_account_icon[0].url" />
      <p class="text-xl mb-4 md:px-24 pb-2 md:h-32">{{accountTypes.singleUserAccount.field_desc[0].value}}</p>

      <div class="bg-white mx-4 p-6 flex flex-col">
        <div class="border-dcborder border-solid border-b py-1">
          <img class="mx-auto" src="@/assets/checkgreen.svg"/>
        </div>
        <div
            v-for="feature in accountTypes.singleUserAccount.termNames"
            v-bind:key="feature"
            class="border-dcborder border-solid border-b py-1 font-semibold">
          {{ feature }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "AccountTypes",
        data: function () {
            return {
              accountTypes : null,
            }
        },
        async created() {
          this.accountTypes = await this.$store.dispatch('getAccountTypes');
        },
    }
</script>

<style scoped>

</style>
