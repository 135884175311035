<template>
  <section class="search-section md:px-0 px-8 md:py-24 py-12 bg-white text-dcblue">

    <div class="section-header mx-auto md:w-6/12 lg:w-3/12 mb-12 text-center">
      <img class="mx-auto mb-6" src="@/assets/search.svg" />
      <h1 class="text-175 font-semibold text-center">Searching for marine <br />regulations has never been easier</h1>
      <router-link class="text-xl" :to="{ name: 'videos' }">Check out our video tutorials to learn more</router-link>
    </div>

    <div class="section-content md:mx-40 lg:mx-44 xl:mx-64">
      <div class="flex wrap justify-around md:mx-5 lg:mx-10 xl:mx-20 mb-10">
          <div class="features">
              <p
                class="uppercase cursor-pointer text-0875 font-semibold tracking-widest"
                :class="{'selected' : subsection == activeSubSection}"
                v-for="(subsection, index) in subSections"
                v-bind:key="subsection.id"
                @click="changeActiveSubsection(index)">
                {{subsection.title}}
              </p>
          </div>

      </div>

      <div class="sub-section-content text-center">
          <img class="mx-auto" src="@/assets/imageSearch.png" />
          <div :class="`mobile-image ${bubbleClass}`">
              <p class="font-bold rounded-full w-6 h-6 bg-dcgreen flex items-center justify-center">
                {{ activeSubSection.id }}
              </p>
          </div>
          <div class="rounded" :class="bubbleClass">
            <p class="font-bold rounded-full w-6 h-6 bg-dcgreen flex items-center justify-center">
              {{ activeSubSection.id }}
            </p>
            <p>{{ activeSubSection.text }}</p>
          </div>
      </div>
    </div>

  </section>
</template>

<script>
    export default {
        name: "SearchSection",
        data: function () {
            return {
                subSections: [
                    {
                        id: 1,
                        title: 'Powerful search',
                        text: 'Search within all regulations by category, keyword or even sentences',
                    },
                    {
                        id: 2,
                        title: 'Smart tags',
                        text: 'Create and manage separate groups of searches with tags',
                    },
                    {
                        id: 3,
                        title: 'Filters',
                        text: 'Narrow down the results according to your preferences',
                    },
                    {
                        id: 4,
                        title: 'Updates',
                        text: 'We notify you for any updated document.',
                    },
                ],
                activeSubSection: null,
            }
        },
        created() {
            if (this.subSections.length > 0) {
                this.activeSubSection = this.subSections[0];
            }
        },
        methods: {
            changeActiveSubsection(index) {
                if(this.subSections[index] != undefined) {
                    this.activeSubSection = this.subSections[index];
                }
            }
        },
        computed: {
            bubbleClass() {
                return 'box' + this.activeSubSection.id + ' ' + 'sb' + this.activeSubSection.id;
            }
        }
    }
</script>

<style scoped lang="scss">
  .section-content {
      .features {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          p {
            margin-bottom: 3px;
            &.selected {
              border-bottom: 3px solid green;
              margin-bottom: 0px;
            }

            @media (max-width: 600px) {
                width: 100%;
                white-space: nowrap;
                margin-right: 30px;
            }
          }

          @media (max-width: 600px) {
              overflow: hidden;
              overflow-x: scroll;
              height: 40px;

              &::-webkit-scrollbar {
                display: none;
              }

          }
      }
  }



  .sub-section-content {
      max-width: 1057px;
      margin: auto;
      position: relative;

      .mobile-image {
          display: none;
      }

      @media (max-width: 600px) {
          img {
              display: none;
          }

          .mobile-image {
              width: calc(100% + 32px);
              display: block;
              height: 400px;
              background-image: url('../../assets/imageSearch.png');
              background-color: #fafafa;
              background-size: cover;
              position: relative;
              box-shadow: 0 0 17px 0 rgba(0,0,0,0.25);
              transition: .5s ease;

              p {
                  position: absolute;
                  transition: .5s ease;
              }
              &.box1.sb1 {
                  p {
                      top: 70px;
                      left: -15px;
                  }
              }

              &.box2.sb2 {
                  p {
                      top: 110px;
                      left: 20px;
                  }
              }

              &.box3.sb3 {
                  p {
                      top: 300px;
                      left: 20px;
                  }
              }

              &.box4.sb4 {
                  background-position: right;
                  left: -32px;
                  p {
                      top: 175px;
                      right: 140px;
                  }
              }

          }
      }
  }

  .box1 {
    top: 21%;
    left: 15%;
    width: 180px;
    height: 160px;
    background: #081B33;
    padding: 20px;
    text-align: left;
    color: #fff;
    position: absolute;
  }

  .sb1:after {
    content: "";
    position: absolute;
    right: 80px;
    top: -12px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #081B33;
  }

  .box2 {
    top: 30%;
    left: 5%;
    width: 180px;
    height: 140px;
    background: #081B33;
    padding: 20px;
    text-align: left;
    color: #fff;
    position: absolute;
  }

  .sb2:after {
    content: "";
    position: absolute;
    right: 80px;
    top: -12px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #081B33;
  }

  .box3 {
    top: 40%;
    left: 20%;
    width: 180px;
    height: 140px;
    margin: 50px auto;
    background: #081B33;
    padding: 20px;
    text-align: left;
    color: #fff;
    position: absolute;
  }

  .sb3:after {
    content: "";
    position: absolute;
    left: -12px;
    top: 60px;
    border-right: 12px solid #081B33;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  .box4 {
    top: 38.5%;
    left: 56.5%;
    width: 190px;
    height: 120px;
    background: #081B33;
    padding: 20px;
    text-align: left;
    color: #fff;
    position: absolute;

    @media only screen and (max-width: 1440px) {
        left: 51%;
        top: 37.5%;
    }
    @media only screen and (max-width: 1024px) {
        left: 40%;
        top: 34.5%;
    }
    @media only screen and (max-width: 766px) {
        left: 45%;
        top: 36%;
    }
  }

  .sb4:after {
    content: "";
    position: absolute;
    right: -12px;
    top: 50px;
    border-left: 12px solid #081B33;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  @media (max-width: 600px) {
    .box1, .box2, .box3, .box4 {
        position: relative;
        width: 100%;
        height: auto;
        margin: 15px 0;
        left: auto;
        top: auto;
        right: auto;
        bottom: auto;
    }

    .sb1:after, .sb2:after, .sb3:after, .sb4:after {
        display: none;
    }
  }

</style>
