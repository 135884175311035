<template>
  <div class="home page-content">
    <HomeHeaderSection />
    <SloganSection />
    <VideoSection id="landingVideo" src="https://www.youtube.com/embed/m1fLGTMeRSc?controls=0" />
    <ImageUnderSection></ImageUnderSection>
  </div>
</template>

<script>
import HomeHeaderSection from "@/components/sections/HomeHeaderSection";
import SloganSection from "../components/sections/SloganSection";
import ImageUnderSection from "../components/sections/ImageUnderSection";
import VideoSection from "../components/sections/VideoSection";
export default {
  name: 'home',
  metaInfo: {
    title: "Stay up to date with any changes to regulations",
    meta: [
            { name: 'title', content: 'MARINEREGULATIONS - Stay up to date with any changes to regulations' },
            { name: 'description', content: 'Our platform provides access to marine legislation, rules and standards including IMO, SOLAS & MARPOL'}
        ],
    },
  components: {
      HomeHeaderSection,
      SloganSection,
      ImageUnderSection,
      VideoSection
  }
}
</script>

<style scoped lang="scss">

</style>
