<template>
  <div class="image-right-section flex flex-wrap md:px-40 px-12 md:py-32 py-12 bg-dcgray text-dcblue">
    <aside class="w-full md:w-1/2 mb-8 flex md:justify-center lg:justify-end text-center md:text-left">
      <div class="self-center md:mx-8 lg:mx-32 w-full md:w-1/2">
        <img class="mb-4 mx-auto md:mx-0" src="@/assets/report.svg" />
        <h1 class="text-175 font-semibold mb-4">Compliance report in seconds</h1>
        <p class="text-xl">Just select the documents and press a button. That simple.</p>
      </div>

    </aside>
    <aside class="w-full md:w-1/2">
      <img class="float-right" src="@/assets/reportpage.png" />
    </aside>
  </div>
</template>

<script>
    export default {
        name: "ImageRightSection"
    }
</script>

<style scoped>

</style>
