<template lang="html">
    <div :id="id" class="block video-block text-dcblue py-10">
        <div class="video-responsive">
            <iframe width="560" height="315" :src="src" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        src: String,
        id: String
    }
}
</script>

<style lang="scss" scoped>
.video-block {
    max-width: 1050px;
    margin: auto;
    padding: 60px 0;
}

.video-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.video-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
</style>
