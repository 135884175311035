import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Packages from "../components/sections/Packages";
import AccountTypes from "../components/sections/AccountTypes";
import AdditionalMaterial from "../components/sections/AdditionalMaterial";
import Features from '../views/Features';
import Videos from '../views/Videos';
import Knowledge from '../views/Knowledge';
import Ships from '../views/Ships';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/features',
    name: 'features',
    component: Features
  },
  {
    path: '/ships',
    name: 'ships',
    component: Ships
  },
  {
    path: '/videos',
    name: 'videos',
    component: Videos
  },
  {
    path: '/access',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "access" */ '../views/Access.vue'),
    children: [
      {path: '', name: 'access', component: Packages},
      {path: 'account-types', name: 'account types', component: AccountTypes},
      {path: 'additional-material', name: 'additional material', component: AdditionalMaterial},
    ]
  },
  {
    path: '/learn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "access" */ '../views/Learn.vue'),
    children: [
      {path: 'tutorials', name: 'tutorials', component: Videos },
      {path: 'knowledge', name: 'knowledge', component: Knowledge},
    ]
  },
  {
    path: '/library',
    name: 'library',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/Library.vue')
  },
  {
    path: '/requestaccess',
    name: 'requestaccess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/RequestAccess.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/About.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/TermsAndConditions.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/support',
    name: 'support',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/Support.vue')
  },
  {
      path: '*',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFoundComponent.vue')
  }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        } else {
          return { x: 0, y: 0 }
        }
    }
})

export default router
