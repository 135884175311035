<template>
  <div class="learn bg-dcgray pb-12 md:pb-64 page-content">
    <div class="header-img">
      <h1 class="self-center text-white text-26875 font-bold">Ships</h1>
    </div>
    <div class="section-content md:mx-10 xl:mx-64 p-5 md:p-0">
      <h2 class="self-center text-175 md:text-26875 font-bold">Regulations for Ships</h2>
      <p class="text-100 md:text-125">A solution for offices and vessels which need to keep an up-to-date library of important rules and regulations</p>
      <ul class="list-item top-list">
        <li>Largest library of maritime documents</li>
        <li>Installation free</li>
        <li>Minimal data use</li>
      </ul>
      <h2 class="self-center text-175 md:text-26875 text-dcblue font-bold mt-10">So, what's different about <span class="text-dcgreen">Marine</span>Regulations?</h2>
      <p class="text-125">MarineRegulations is cloud-based and simple. It solves the problem of delivering
        physical goods to a ship (hard copy publications, USBs with software) and doesn't
        require any installation. Access for ships and document control can easily be
        managed via your company office.
      </p>
      <img src="../assets/Marine-Reg-2.png" alt="">
      <h2 class="self-center text-175 md:text-26875 text-dcblue font-bold mt-10">Features and Benefits</h2>
      <div class="md:flex px-5 xl:px-20 py-5 md:py-10">
        <div class="mr-0 md:mr-10">
          <h3 class="font-bold text-125">Shore-side</h3>
          <p class=" text-100 md:text-125">Web access to all the content you need</p>
        <ul class="features-list">
          <li>Easy to manage access for fleet</li>
          <li>Share documents from office to vessel</li>
          <li>Set-up your own notifications</li>
          <li>View of forthcoming legislation</li>
          <li>Best in class search and filtering</li>
        </ul>
        </div>
        <div class="mt-10 md:mt-0 ml-0 md:ml-70">
          <h3 class="font-bold text-125 mt-5 md:mt-0">On-board</h3>
          <p class="text-100 md:text-125">Easily save and back-up the mandatory documents</p>
        <ul class="features-list">
          <li>Installation free</li>
          <li>Offline access to documents</li>
          <li>Updates automatically when online</li>
          <li>Cybersecure</li>
          <li>Compliant with IMO/Flag carriage requirements</li>
        </ul>
        </div>
      </div>
      <h2 class="self-center text-26875 text-dcblue font-bold mt-10">The Process</h2>
      <p class="text-125">Get access to what your business requires. What the ship needs can be
        made available offline. Be notified when a regulation has been updated or a
        new document has been published via personalised updates. When the vessel
        is online only the required updates are downloaded and therefore, only the
        minimum amount of data is used. It's really quite simple - and that's how it
        should be
      </p>
      <img src="../assets/Marine-Reg-1.png" alt="">
    </div>
  </div>
</template>

<script>
    export default {
      name: "Ships",
      computed: {},
      methods: {}

    }
</script>

<style scoped lang="scss">
img {
  margin-top: 20px;
}
.top-list li:before {
  margin-right: 10px;
  content: '✓';
  color: #5ABA47;
}
.features-list li:before {
  margin-right: 10px;
  content: '◼';
  color: #5ABA47;
}
li {
  margin-top: 20px;
  font-weight: bold;
  font-size: 24px;

  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
}
</style>
