<template lang="html">
    <div class="flex flex-wrap  md:w-2/3 lg:w-1/2 mx-8 md:mx-auto md:ml-auto justify-around md:py-10">
      <div class="bullet-point flex md:w-1/3 w-full">
        <img src="@/assets/checkgreen.svg" class="mr-2 md:mr-4" />
        <p class="text-xl">Save time</p>
      </div>
      <div class="bullet-point flex md:w-1/3 w-full">
        <img src="@/assets/checkgreen.svg" class="mr-2 md:mr-4" />
        <p class="text-xl">Minimize risk</p>
      </div>
      <div class="bullet-point flex md:w-1/3 w-full">
        <img src="@/assets/checkgreen.svg" class="mr-2 md:mr-4" />
        <p class="text-xl">Always up-to-date</p>
      </div>
    </div>
</template>

<script>
export default {
    name: 'SloganSection'
}
</script>

<style lang="scss" scoped>
</style>
