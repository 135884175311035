<template>
  <div id="app" class="container-xl mx-auto font-futura">
    <div v-if="false" class="loader fixed w-screen h-screen flex justify-center">
      <img src="@/assets/loading.svg" />
    </div>
    <div id="nav" class="p-6 flex flex-row justify-between bg-white font-futura fixed w-full">
      <div class="">
        <router-link to="/">
            <img src="@/assets/logo.svg" class="self-center hidden md:block lg:block"/>
            <img src="@/assets/logo-small.png" class="mobile-logo self-center block md:hidden"/>
        </router-link>
      </div>

      <div id="menuItems" class="menu-items flex flex-row items-center">
        <router-link to="/ships" @click="handleMobileClick" class="internal-nav-item-sm md:internal-nav-item">Ships</router-link>
        <router-link to="/features" @click="handleMobileClick" class="internal-nav-item-sm md:internal-nav-item">Features</router-link>
        <router-link to="/learn" @click="handleMobileClick" class="internal-nav-item-sm md:internal-nav-item">Learn</router-link>
        <!-- <div id="dropdown" class="relative">
            <button to="/learn" @click="handleDropdownClick" class="internal-nav-item-sm md:internal-nav-item">Learn</button>
            <div  v-if="isDropdownActive" class="absolute bg-white mt-2 shadow-xl w-48 rounded-lg">
                <router-link to="/videos" @click="handleDropdownClick" class="block internal-nav-item-sm md:internal-nav-item px-4 py-2">Videos</router-link>
                <router-link to="/learn/see-the-platform-in-action" @click="handleDropdownClick" class="block internal-nav-item-sm md:internal-nav-item px-4 py-2">See the platform in action</router-link>
                <router-link to="/learn/knowledge" @click="handleDropdownClick" class="block internal-nav-item-sm md:internal-nav-item px-4 py-2">Knowledge</router-link>
                <router-link to="/learn/more" @click="handleDropdownClick" class="block internal-nav-item-sm md:internal-nav-item px-4 py-2">Learn more about marine regulations</router-link>
            </div>
        </div> -->

        <router-link to="/library" @click="handleMobileClick" class="internal-nav-item-sm md:internal-nav-item">Library</router-link>
        <router-link to="/access" @click="handleMobileClick" class="internal-nav-item-sm md:internal-nav-item">Pricing & Plans</router-link>
        <a href="https://www.marineregulations.news/" class="internal-nav-item-sm md:internal-nav-item">News</a>
        <router-link to="/about" @click="handleMobileClick" class="internal-nav-item-sm md:internal-nav-item">About</router-link>
        <router-link to="/support" @click="handleMobileClick" class="internal-nav-item-sm md:internal-nav-item">Contact</router-link>
        <router-link to="/signup" @click="handleMobileClick" class="signup mx-2 md:ml-4 md:mr-2 px-2 md:px-8 py-1 md:py-2 bg-dcgreen text-white text-sm font-semibold uppercase font-futura">Free trial</router-link>
        <a href="https://marineregulations.dandocs.com/login"  class="login mx-2 md:mr-4 md:ml-2 px-2 md:px-8 py-1 md:py-2 bg-dcblue text-white text-sm font-semibold uppercase font-futura">Log in</a>
        <router-link to="/privacy-policy"><img class="cookie-warning" src="/img/icons/cookieicon.svg" alt="Cookie warning" /></router-link>
        <router-link to="/about" class="md:hidden internal-nav-item-sm md:internal-nav-item">About us</router-link>
        <router-link to="/terms" class="md:hidden internal-nav-item-sm md:internal-nav-item">Terms of Use</router-link>
        <router-link to="/support" class="md:hidden internal-nav-item-sm md:internal-nav-item">Support</router-link>
      </div>
      <div class=" block md:hidden z-50">
          <button v-if="!menuActive" @click="handleMobileClick" class="flex items-center px-3 py-2">
              <svg class="fill-current" width="25px" height="22px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
          <button v-else @click="handleMobileClick" class="flex items-center px-3 py-2 text-black-200">
              <svg width="25px" height="22px" viewBox="0 0 25 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <!-- Generator: sketchtool 63 (101010) - https://sketch.com -->
                  <title>520B54AF-2875-4C28-BE54-B6D5D0984004</title>
                  <desc>Created with sketchtool.</desc>
                  <g id="Sales-mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="mob-menu" transform="translate(-330.000000, -25.000000)">
                          <rect id="Rectangle" stroke="#979797" fill="#FFFFFF" x="1" y="1" width="375" height="713"></rect>
                          <g id="top-bar-mobile-copy" transform="translate(1.000000, 1.000000)">
                              <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="375" height="70"></rect>
                              <g id="icn-menu" transform="translate(332.000000, 26.000000)" fill="#081B33">
                                  <rect id="Rectangle-Copy" transform="translate(9.500000, 9.500000) rotate(-225.000000) translate(-9.500000, -9.500000) " x="1.24344979e-13" y="8" width="19" height="3"></rect>
                                  <rect id="Rectangle-Copy" transform="translate(9.500000, 9.500000) rotate(-135.000000) translate(-9.500000, -9.500000) " x="2.34479103e-13" y="8" width="19" height="3"></rect>
                              </g>
                          </g>
                      </g>
                  </g>
              </svg>
          </button>
      </div>
    </div>

    <!-- <nav class="flex items-center justify-between flex-wrap bg-white p-6">
        <div class="flex items-center flex-shrink-0 text-white mr-6">
            <router-link to="/"> <img src="@/assets/logo.svg" class="self-center"/></router-link>
        </div>
        <div class="block lg:hidden">
            <button class="flex items-center px-3 py-2 border rounded text-black-200 border-black-400 hover:text-black hover:border-black">
                <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
            </button>
        </div>
        <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <div class="text-sm lg:flex-grow">
                <router-link to="/library" class="internal-nav-item-sm md:internal-nav-item">Library</router-link>
                <router-link to="/access" class="internal-nav-item-sm md:internal-nav-item">Access</router-link>
                <router-link to="/signup" class="mx-2 md:mx-8 px-2 md:px-8 py-1 md:py-2 bg-dcgreen text-white text-sm font-semibold uppercase font-futura">Free trial</router-link>
            </div>
      </div>
    </nav> -->

    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
    import Footer from "@/components/Footer";

    export default {
        name: "app",
        metaInfo: {
            titleTemplate: "MARINEREGULATIONS - %s"
        },
        components: {
            Footer
        },
        data() {
            return {
                menuActive: false,
                isDropdownActive: false
            }
        },
        watch: {
            $route() {
                if(this.menuActive) this.handleMobileClick()
                if(this.isDropdownActive) this.handleDropdownClick()
            }
        },
        created() {
            this.$store.dispatch('loadAllTerms');
        },
        methods: {
            handleMobileClick() {
                this.menuActive = !this.menuActive;
                document.getElementById('menuItems').classList.toggle("show")
            },
            handleDropdownClick() {
                this.isDropdownActive = !this.isDropdownActive;
            }
        }
    }
</script>

<style lang="scss" scoped>

    #nav {
        z-index: 1;
    }

    .loader {
        background: rgba(255, 255, 255, 0.5);
    }

    .mobile-logo {
        max-width: 215px;
    }

    .cookie-warning {
        max-width: 30px;
    }

    @media only screen and (max-width: 767px) {
        .menu-items {
            position: absolute;
            flex-direction: column;
            top: 86px;
            right: 0;
            width: 100%;
            height: 0;
            background-color: #081B33;
            display: flex;
            transition: height .5s ease;
            z-index: 2;
            overflow: scroll;

            &.show {
                height: calc(100vh - 86px);
            }

            a {
                margin: 0 30px;
                padding: 15px;
                color: #FFF;
                font-size: 26px;
                order: 3;

                &.signup {
                    margin: 30px;
                    order: 1;
                    text-align: center;
                    font-size: 18px;
                }

                &.login {
                    order: 2;
                    text-align: center;
                    background-color: #FFF;
                    color: #081a33;
                    margin-bottom: 30px;
                    font-size: 18px;
                }
            }
        }
    }

</style>
