<template>
  <section class="home-header-section">
    <div class="hero bg-cover flex items-center justify-between">
      <aside class="mx-8 xl:mx-auto my-4">
          <div class="xl:w-2/3 xs:w-full mx-auto text-center py-8">
              <h1 class="w-auto font-bold text-white text-275 mb-2 md:mb-8 leading-tight">"Why fall behind, <br> stay ahead with <br> MARINEREGULATIONS"</h1>
              <h2 class="text-white text-base font-semibold mb-8 md:mb-8">Do you need to be kept up to date with latest IMO, SOLAS and MARPOL rules? Our platform will ensure you are first to know about any changes to any marine regulations.</h2>
              <div class="flex flex-row flex-wrap justify-center">
                <router-link to="/signup" class="px-4 sm:px-8 md:px-12 py-3 md:py-3 mr-4 bg-dcgreen text-white text-lg md:text-lg font-bold uppercase">Try for free</router-link>
                <button v-on:click="handleVideoClick" class="px-4 sm:px-8 md:px-12 py-3 md:py-3 ml-4 bg-dcgreen text-white text-lg md:text-lg font-bold uppercase">Learn More</button>
              </div>
          </div>
      </aside>
      <aside class="right">
        <img src="@/assets/heroUI.png" class="mt-24 md:mt-32"/>
      </aside>
    </div>
  </section>
</template>

<script>
    export default {
        name: "HomeHeaderSection",
        methods: {
            handleVideoClick() {
                let anchor = document.getElementById('landingVideo');
                if(anchor) {
                    anchor.scrollIntoView({ behavior: 'smooth' });
                }
            },
        }
    }
</script>

<style scoped lang="scss">
  .home-header-section {
    .hero {
      background-image: url('../../assets/heroback.jpg');

      @media (max-width: 770px) {
          flex-direction: column;

          aside.right {
              max-width: 100%;

              img {
                  margin-top: 0;
              }
          }
      }

      @media (max-width: 600px) {
          background-image: none;
          background-color: #081B33;
          height: auto;

          h1 {
              font-size: 31px;
          }

          aside.right {
              display: none;
          }

          p {
              font-size: 20px;
          }
      }
    }

    .right {
        max-width: 50%;
    }

    @media (max-width: 600px) {
        .bullet-point {
            border-bottom: 1px solid #D5D5D5;
            padding: 15px 0;
        }
    }
  }
</style>
