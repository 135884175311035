/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    terms: [],
    flag_states: [],
    library: [],
    other_contents: [],
    loading_queue: [],
    country_codes: [],
    standards: [],
    un: []
  },
  mutations: {
    set_terms (state, payload) {
      state.terms = payload.data;
    },
    set_flag_states (state, payload) {
      state.flag_states = payload.data;
    },
    set_other_contents (state, payload) {
      state.other_contents = payload.data;
    },
    set_country_codes (state, payload) {
      state.country_codes = payload.data;
    },
    set_library (state, payload) {
      state.library = payload.data;
    },
    add_loading_item(state, key) {
      state.loading[key] = true;
    },
    remove_loading_item(state, key) {
      delete state.loading[key];
    },
    set_standards(state, payload) {
        state.standards = payload.data;
    },
    set_un(state, payload) {
        state.un = payload.data;
    }
  },
  actions: {
    async loadAllTerms (context) {
      let response = await Vue.axios.get('/all_terms?_format=json');
      context.commit('set_terms', response);
    },
    async loadFlagStates(context) {
      let response = await Vue.axios.get('/flag_states?_format=json');
      context.commit('set_flag_states', response);
    },
    async loadOtherContents(context) {
      let response = await Vue.axios.get('/other_content?_format=json');
      context.commit('set_other_contents', response);
    },
    async loadStandards(context) {
      let response = await Vue.axios.get('/standards?_format=json');
      context.commit('set_standards', response);
    },
    async loadCountryCodes(context) {
      let response = await Vue.axios.get('/country_codes?_format=json');
      context.commit('set_country_codes', response);
    },
    async loadLibraries(context) {
      let response = await Vue.axios.get('/libraries?_format=json');
      context.commit('set_library', response);
    },
    async loadUN(context) {
      let response = await Vue.axios.get('/un?_format=json');
      context.commit('set_un', response);
    },

    async getAccountTypes({context, dispatch}) {
      let response = await Promise.all([
        Vue.axios.get('/taxonomy/term/59?_format=json'),
        Vue.axios.get('/taxonomy/term/60?_format=json')
      ]);

      let corporateAccount = await dispatch('parseFeatureTermNames', response[0].data);
      let singleUserAccount = await dispatch('parseFeatureTermNames', response[1].data);

      return {
          'corporateAccount': corporateAccount,
          'singleUserAccount': singleUserAccount,
      }
    },

    async parseFeatureTermNames({context, state}, data) {
      return new Promise((resolve) => {
        let termNames = []

        data.field_account_features.forEach((termRef) => {
          state.terms.forEach((term) => {
            if (term.tid[0].value === termRef.target_id) {
              termNames.push(term.name[0].value);
            }
          });
        });

        data.termNames = termNames;

        resolve(data);
      });
    },

  },
  modules: {
  },
  getters: {
    getFlagStates: state => {
      return state.flag_states;
    },
    getAllTerms: state => {
      return state.terms;
    },
    getOtherContents: state => {
      return state.other_contents;
    },
    getCountryCodes: state => {
      return state.country_codes;
    },
    getLibrary: state => {
      return state.library;
    },
    getStandards: state => {
      return state.standards;
    },
    getUN: state => {
        return state.un
    }
  }
})
