<template lang="html">
    <ComingSoon />
</template>

<script>
import ComingSoon from './ComingSoon'
export default {
    components: {
        ComingSoon
    }
}
</script>

<style lang="css" scoped>
</style>
