<template>
  <div class="image-under-section pt-24 pb-32 bg-blue-800">

    <div class="mx-auto md:w-1/3 w-full text-center text-white px-8 mb-12">
      <p class="text-xl">Check out our Library of more than 80,000 marine regulations, up-to date and easy to browse </p>
    </div>

    <img src="../../assets/table.jpg" alt="" class="tableImg">

    <!-- <div class="mx-auto md:w-760 w-3/4 bg-white px-12 py-8">
      <h1 class="text-center text-lg sm:text-xl md:text-175 font-semibold mb-3">The <strong>MARINE</strong>REGULATIONS Library</h1>
      <div class="flex flex-wrap">

        <div class="md:w-1/3 w-full md:px-10">
          <h3 class="font-semibold border-b-2">IMO</h3>
          <div class="h-auto">
            <ul class="py-1">
              <li v-for="term in libraries.imo" v-bind:key="term.name"> {{ term.name }}</li>
            </ul>
          </div>

          <h3 class="font-semibold border-b-2">EU</h3>
          <div class="h-auto">
            <ul class="py-1">
              <li v-for="term in libraries.eu" v-bind:key="term.name"> {{ term.name }}</li>
            </ul>
          </div>

          <h3 class="font-semibold border-b-2">STANDARDS</h3>
          <div class="h-auto">
            <ul class="py-1">
              <li v-for="term in libraries.standards" v-bind:key="term.name"> {{ term.name }}</li>
            </ul>
          </div>
        </div>

        <div class="md:w-1/3 w-full md:px-10">
          <h3 class="font-semibold border-b-2">CLASSIFICATION SOCIETIES</h3>
          <div class="h-auto">
            <ul class="py-1">
              <li v-for="term in libraries.classification_societies" v-bind:key="term.name"> {{ term.name }}</li>
            </ul>
          </div>
        </div>

        <div class="md:w-1/3 w-full md:px-10">
          <h3 class="font-semibold border-b-2">FLAG STATES</h3>
          <div class="h-auto">
            <ul class="py-1">
              <li v-for="term in libraries.flag_states" v-bind:key="term.name"> {{ term.long_name }}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="w-full h-16 bg-blue">

        <router-link to="/library" class="bg-white mt-4 m-auto block border-2 w-48 text-center border-dcblue font-bold py-1">SEE THE FULL LIST</router-link>
      </div>
    </div> -->

  </div>
</template>

<script>

    export default {
        name: "ImageUnderSection",
        data: function () {
            return {
                libraries: {
                    imo: [],
                    eu: [],
                    standards: [],
                    classification_societies: [],
                    flag_states: []
                }
            }
        },
        created() {
            let _self = this;

            Object.keys(_self.libraries).forEach(async (key) => {
                let response = await _self.axios.get('/library/' + key + '?_format=json');
                _self.libraries[key] = response.data;
            })
        },
    }
</script>

<style scoped>
  .image-under-section {
    background-image: url('../../assets/backlibrary.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .tableImg {
    margin: auto;
  }
</style>
