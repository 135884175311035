<template>
  <div class="image-left-section flex flex-wrap-reverse dlex px-8 md:py-32 py-12 bg-dcgray text-dcblue">

    <aside class="saved-search w-full md:w-1/2">
      <img class="float-left" src="@/assets/notifications.png" />
      <div class="mobile-image">
      </div>
    </aside>

    <aside class="w-full md:w-1/2 justify-start">
      <div class=" self-center md:mx-40 pt-6 md:pt-0 md:w-2/5 text-center md:text-left">
        <img class="mb-4 mx-auto  md:mx-0" src="@/assets/save.svg" />

        <h1 class="text-175 font-semibold mb-4">Saved searches and alerts</h1>
        <p class="text-xl">Save and share searches based in keywords and filtering and MarineRegulations sends you a message anytime there is an update in related documents.</p>
      </div>
    </aside>

  </div>
</template>

<script>
    export default {
        name: "ImageLeftSection"
    }
</script>

<style lang="scss" scoped>
    .saved-search {
        .mobile-image {
            display: none;
        }
        @media (max-width: 600px) {
            img {
                display: none;
            }

            .mobile-image {
                margin-top: 30px;
                display: block;
                height: 375px;
                background-image: url('../../assets/notifications.png');
                background-size: cover;
                width: calc(100% + 32px);
            }
        }
    }
</style>
